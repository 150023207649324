/* eslint-disable */

import React, { useState } from "react";
import Logo from "./Logo";
import InstagramIcon from "./InstagramIcon";
import { Link } from "gatsby";

const Navbar = ({ active, toggle }) => {
  // const [active, setActive] = useState(false);
  return (
    <nav
      role="navigation"
      aria-label="main-navigation"
      className="sticky top-0 bg-beige z-20 lg:px-0 px-8"
    >
      <div className="flex justify-between items-center max-w-6xl mx-auto md:py-6 py-5">
        <div>
          <div className="lg:w-64 w-48">
            <Link to="/#">
              <Logo />
            </Link>
          </div>
        </div>
        <div id="navMenu">
          <div
            onClick={toggle}
            className={`flex transition duration-300 md:invisible ${
              active ? `opacity-0 invisible` : `opacity-100 visible`
            }`}
          >
            <div className="ml-auto mr-0">
              <svg width="26" height="14" viewBox="0 0 26 14" fill="none">
                <rect width="26" height="2" fill="black" />
                <rect y="6" width="26" height="2" fill="black" />
                <rect y="12" width="26" height="2" fill="black" />
              </svg>
            </div>
          </div>
          <ul
            className={`flex md:flex-row flex-col font-serif text-lg md:static top-0 left-0 w-full md:h-auto transition duration-300 md:opacity-100 md:items-center gap-4 ${
              active
                ? "h-screen z-20 fixed opacity-100 justify-center px-8 bg-beige "
                : "h-0 opacity-0 bg-transparent"
            }`}
          >
            <div
              onClick={toggle}
              className={`absolute top-0 right-0 mt-4 mr-8 transition duration-300 md:invisible ${
                active ? "opactiy-100 visible" : "opacity-0 invisible"
              }`}
            >
              <div className="w-8 h-8">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  fill="#000000"
                  viewBox="0 0 50 50"
                  enableBackground="new 0 0 50 50"
                >
                  <path
                    d="M9.016,40.837c0.195,0.195,0.451,0.292,0.707,0.292c0.256,0,0.512-0.098,0.708-0.293l14.292-14.309
	l14.292,14.309c0.195,0.196,0.451,0.293,0.708,0.293c0.256,0,0.512-0.098,0.707-0.292c0.391-0.39,0.391-1.023,0.001-1.414
	L26.153,25.129L40.43,10.836c0.39-0.391,0.39-1.024-0.001-1.414c-0.392-0.391-1.024-0.391-1.414,0.001L24.722,23.732L10.43,9.423
	c-0.391-0.391-1.024-0.391-1.414-0.001c-0.391,0.39-0.391,1.023-0.001,1.414l14.276,14.293L9.015,39.423
	C8.625,39.813,8.625,40.447,9.016,40.837z"
                  />
                </svg>
              </div>
            </div>
            <li
              className={`md:block ${active ? null : "hidden"}`}
              onClick={toggle}
            >
              <Link to="/#story" className="">
                <span className="border-b border-transparent hover:border-black transition-all duration-100 pb-1 md:text-base text-3xl">
                  The Story
                </span>
              </Link>
            </li>

            <li
              className={`md:block ${active ? null : "hidden"}`}
              onClick={toggle}
            >
              <Link to="/#author" className="">
                <span className="border-b border-transparent hover:border-black transition-all duration-100 pb-1 md:text-base text-3xl">
                  About the Author
                </span>
              </Link>
            </li>

            <li
              className={`md:block ${active ? null : "hidden"}`}
              onClick={toggle}
            >
              <Link to="/#contact" className="">
                <span className="border-b border-transparent hover:border-black transition-all duration-100 pb-1 md:text-base text-3xl">
                  Contact
                </span>
              </Link>
            </li>

            {/* <li
              className={`md:block ${active ? null : "hidden"}`}
              onClick={toggle}
            >
              <Link to="/#support" className="mr-4">
                <span className="border-b border-transparent hover:border-black transition-all duration-100 pb-1 md:text-base text-3xl">
                  Download
                </span>
              </Link>
              https://mylifeinamber.gumroad.com/l/cfmcz
            </li> */}

            <li
              className={`md:block flex items-center md:mt-0 mt-8 ${
                active ? null : "hidden"
              }`}
            >
              <a
                href="https://instagram.com/mylifeinamber"
                target="_blank"
                rel="noopener noreferrer"
                to="/contact/examples"
              >
                <InstagramIcon />
              </a>
            </li>

            {/* <li
              className={`md:block flex items-center md:mt-0 mt-8 ${
                active ? null : "hidden"
              }`}
            >
              <a
                href="https://mylifeinamber.gumroad.com/l/cfmcz"
                target="_blank"
                rel="noopener noreferrer"
                className="font-serif text-white text-lg mt-6"
              >
                <span className="bg-brick rounded-sm text-white focus:outline-none px-5 py-3 mt-6 ml-0 text-lg font-serif uppercase">
                  Pre-Order Now
                </span>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
