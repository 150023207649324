/* eslint-disable */

import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import axios from "axios";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "../styles/donate-form.css";
import countries from "../data/countries.json";
import Field from "./Field";

const STRIPE_LAMBDA_URL =
  process.env.NODE_ENV === "production"
    ? "https://gwek30j4n1.execute-api.us-east-1.amazonaws.com/prod"
    : "https://xxbb86gq4m.execute-api.us-east-1.amazonaws.com/dev";

const billingDefaults = {
  firstName: "",
  lastName: "",
  email: "",
  address1: "",
  address2: "",
  city: "",
  province: "",
  postal: "",
  country: "",
};

export default () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [freeDownload, setFreeDownload] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentShow, setPaymentShow] = useState(false);
  const [billingShow, setBillingShow] = useState(false);
  // const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [paymentIntentError, setPaymentIntentError] = useState(false);
  const [mailGun, setMailGun] = useState(null);
  const [amount, setAmount] = useState("");
  const [billingDetails, setBillingDetails] = useState({ ...billingDefaults });

  const handleSubmit = async (event) => {
    event.preventDefault();

    navigate("/#support");

    if (freeDownload) {
      await handleMailGun();
    } else {
      await handleStripe();
    }
  };

  const handleReset = async () => {
    setPaymentIntentError(false);
  };

  const handleMailGun = async () => {
    const data = {
      amount: amount,
      email: billingDetails.email,
      firstName: billingDetails.firstName,
      lastName: billingDetails.lastName,
    };

    const mg = await axios({
      method: "post",
      url: `${STRIPE_LAMBDA_URL}/mail`,
      data,
    });

    setProcessing(false);
    setMailGun(mg);
  };

  const handlePaymentIntent = async (id) => {
    const data = {
      amount: amount * 100,
      paymentMethod: id,
    };
    // console.log(`${STRIPE_LAMBDA_URL}/billing`);
    return await axios({
      method: "post",
      url: `${STRIPE_LAMBDA_URL}/billing`,
      data,
    });
  };

  const handleStripe = async (event) => {
    if (!stripe || !elements) {
      return;
    }
    if (amount < 3) {
      return;
    }
    if (error) {
      setError(error);
      return;
    }
    if (cardComplete) {
      setProcessing(true);
    }

    try {
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });

      if (paymentMethod.err) {
        setError(paymentMethod.err);
      }

      const paymentIntent = await handlePaymentIntent(paymentMethod.id);

      setProcessing(false);
      setPaymentIntent(paymentIntent.data);
    } catch (err) {
      setProcessing(false);
      setPaymentIntentError(true);
      // console.log(err);
    }
  };

  useEffect(() => {
    console.log(paymentIntent);
  }, [paymentIntent]);

  useEffect(() => {
    if (parseInt(amount) === 0) {
      setFreeDownload(true);
      setPaymentShow(true);
    } else if (parseInt(amount) >= 3) {
      setPaymentShow(true);
      setBillingShow(true);
    } else {
      setFreeDownload(false);
    }
  }, [amount]);

  const handlePaymentConfirm = async (secret) => {
    try {
      const response = await stripe.confirmCardPayment(secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            email: billingDetails.email,
            name: `${billingDetails.firstName} ${billingDetails.lastName}`,
            address: {
              city: billingDetails.city,
              country: billingDetails.country,
              line1: billingDetails.address1,
              line2: billingDetails.address2,
              postal_code: billingDetails.postal,
              state: billingDetails.province,
            },
          },
        },
      });
      setPaymentIntent(response.paymentIntent);
      handleMailGun();
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <>
      {processing && (
        <>
          <div className="font-serif text-lg bg-beige rounded-md w-full h-full flex flex-col justify-center items-center p-8">
            Processing...
          </div>
        </>
      )}

      <form
        onSubmit={handleSubmit}
        className={`w-full ${
          processing || paymentIntent || paymentIntentError || mailGun
            ? "hidden"
            : null
        }`}
      >
        <fieldset>
          <Field
            label="Amount"
            id="amount"
            type="number"
            required
            value={amount}
            min="0"
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            error={amount > 0 && amount < 3 && "Minimum $3"}
          />
        </fieldset>

        {/* Payment form */}
        <div className="py-2">
          <div className="flex justify-between items-center pt-3 border-t border-black">
            <h4 className="font-serif text-lg">
              {freeDownload ? "Contact Information" : "Payment"}
            </h4>
            <div
              className="w-5 h-5 flex justify-center items-center cursor-pointer text-lg bg-brick text-white rounded-full"
              onClick={() => setPaymentShow(!paymentShow)}
            >
              <span className="font-mono">{paymentShow ? "-" : "+"}</span>
            </div>
          </div>
          <div>
            <fieldset className={paymentShow ? "" : "h-0 invisible"}>
              <div className="grid md:grid-cols-2 grid-cols-1 col-gap-4">
                <Field
                  label="First Name"
                  id="first-name"
                  type="text"
                  placeholder=""
                  required
                  value={billingDetails.firstName}
                  onChange={(e) => {
                    setBillingDetails({
                      ...billingDetails,
                      firstName: e.target.value,
                    });
                  }}
                />
                <Field
                  label="Last Name"
                  id="last-name"
                  type="text"
                  placeholder=""
                  required
                  value={billingDetails.lastName}
                  onChange={(e) => {
                    setBillingDetails({
                      ...billingDetails,
                      lastName: e.target.value,
                    });
                  }}
                />
              </div>
              {!freeDownload && (
                <div className="flex flex-col my-3">
                  <label className="font-serif text-left italic">
                    Card Number
                  </label>
                  <CardNumberElement
                    className="bg-transparent border border-black font-serif px-3 py-3 focus:outline-none focus:bg-beige"
                    onChange={(e) => {
                      setError(e.error);
                      setCardComplete(e.complete);
                    }}
                  />
                </div>
              )}
              {!freeDownload && (
                <div className="grid md:grid-cols-2 grid-cols-1 col-gap-4">
                  <div className="flex flex-col my-3">
                    <label className="font-serif text-left italic">
                      Card Exp. Date
                    </label>
                    <CardExpiryElement className="bg-transparent border border-black font-serif px-3 py-3 focus:outline-none focus:bg-beige" />
                  </div>
                  <div className="FormRow flex flex-col my-3">
                    <label className="FormRowLabel font-serif text-left italic">
                      CVC
                    </label>
                    <CardCvcElement className="bg-transparent border border-black font-serif px-3 py-3 focus:outline-none focus:bg-beige" />
                  </div>
                </div>
              )}

              <Field
                label="Email"
                id="email"
                type="email"
                placeholder=""
                required
                value={billingDetails.email}
                onChange={(e) => {
                  setBillingDetails({
                    ...billingDetails,
                    email: e.target.value,
                  });
                }}
              />
            </fieldset>
          </div>
        </div>

        {/* Billing Form */}
        {!freeDownload && (
          <div className="py-2">
            <div className="flex justify-between items-center pt-3 border-t border-black">
              <h4 className="font-serif text-lg">Billing</h4>
              <div
                className="w-5 h-5 flex justify-center items-center cursor-pointer text-lg bg-brick text-white rounded-full"
                onClick={() => setBillingShow(!billingShow)}
              >
                <span className="font-mono">{billingShow ? "-" : "+"}</span>
              </div>
            </div>
            <div>
              <fieldset className={billingShow ? null : "h-0 invisible"}>
                <Field
                  label="Address 1"
                  id="address1"
                  type="text"
                  placeholder=""
                  required
                  value={billingDetails.address1}
                  onChange={(e) => {
                    setBillingDetails({
                      ...billingDetails,
                      address1: e.target.value,
                    });
                  }}
                />
                <Field
                  label="Address 2"
                  id="address2"
                  type="text"
                  placeholder=""
                  value={billingDetails.address2}
                  onChange={(e) => {
                    setBillingDetails({
                      ...billingDetails,
                      address2: e.target.value,
                    });
                  }}
                />
                <div className="grid md:grid-cols-2 grid-cols-1 col-gap-4">
                  <Field
                    label="City"
                    id="city"
                    type="text"
                    placeholder=""
                    required
                    value={billingDetails.city}
                    onChange={(e) => {
                      setBillingDetails({
                        ...billingDetails,
                        city: e.target.value,
                      });
                    }}
                  />
                  <Field
                    label="Province/State"
                    id="city"
                    type="text"
                    placeholder=""
                    required
                    value={billingDetails.province}
                    onChange={(e) => {
                      setBillingDetails({
                        ...billingDetails,
                        province: e.target.value,
                      });
                    }}
                  />

                  <Field
                    label="Postal/Zip Code*"
                    id="city"
                    type="text"
                    placeholder=""
                    required
                    value={billingDetails.postal}
                    onChange={(e) => {
                      setBillingDetails({
                        ...billingDetails,
                        postal: e.target.value,
                      });
                    }}
                  />
                  <div className="flex flex-col my-3">
                    <label
                      htmlFor="country"
                      className="font-serif text-left italic"
                    >
                      Country Code
                    </label>
                    <select
                      required
                      id="country"
                      className="rounded-none bg-transparent border border-black font-serif px-3 py-2 focus:outline-none focus:bg-beige"
                      value={billingDetails.country}
                      onChange={(e) => {
                        setBillingDetails({
                          ...billingDetails,
                          country: e.target.value,
                        });
                      }}
                    >
                      <option className="rounded-none" value="" hidden>
                        Select a country
                      </option>
                      {countries.map((country, i) => (
                        <option
                          className="rounded-none"
                          value={country.code}
                          key={country.code}
                        >
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        )}

        {freeDownload ? (
          <button
            type="submit"
            className="bg-brick text-white focus:outline-none px-5 py-3 mt-6 ml-0 text-lg font-serif uppercase disabled:opacity-75"
          >
            Download
          </button>
        ) : (
          <button
            type="submit"
            className="bg-brick text-white focus:outline-none px-5 py-3 mt-6 ml-0 text-lg font-serif uppercase"
            disabled={!stripe}
          >
            Download
          </button>
        )}
      </form>

      {paymentIntentError && (
        <>
          <div className="font-serif text-lg bg-beige rounded-md w-full h-full flex flex-col justify-center items-center p-8">
            Connection error, please try again.
            <button
              className="bg-brick text-white focus:outline-none px-5 py-3 mt-6 ml-0 text-lg font-serif uppercase"
              type="button"
              onClick={() => handleReset()}
            >
              Back
            </button>
          </div>
        </>
      )}

      {paymentIntent && paymentIntent.status === "requires_confirmation" && (
        <>
          <div className="font-serif text-lg bg-beige rounded-md w-full h-full flex flex-col justify-center items-center p-8">
            Are you sure you want to pay {amount} to mylifeinamber.com?
            <button
              className="bg-brick text-white focus:outline-none px-5 py-3 mt-6 ml-0 text-lg font-serif uppercase"
              type="button"
              onClick={() => handlePaymentConfirm(paymentIntent.client_secret)}
            >
              Proceed
            </button>
          </div>
        </>
      )}

      {paymentIntent && paymentIntent.status === "succeeded" && (
        <>
          <div className="font-serif text-lg bg-beige rounded-md w-full h-full flex flex-col justify-center items-center ">
            <div className="p-8">
              <p className="mb-4">
                Thanks for your support. And email with the book chapter was
                just sent to your email!
              </p>
              <p>
                Reach out to{" "}
                <a href="mailto:sarah@mylifeinamber.com">
                  <span class="italic underline">sarah@mylifeinamber.com</span>
                </a>{" "}
                if you have any issues{" "}
              </p>
            </div>
          </div>
        </>
      )}

      {!paymentIntent && mailGun && (
        <>
          <div className="font-serif text-lg bg-beige rounded-md w-full h-full flex flex-col justify-center items-center ">
            <div class="p-8">
              <p>Thank you {billingDetails.firstName}!</p>
              <p className="mb-4">
                Download link has been sent to&nbsp;
                {billingDetails.email}
              </p>
              <p>
                Reach out to{" "}
                <a href="mailto:sarah@mylifeinamber.com">
                  <span class="italic underline">sarah@mylifeinamber.com</span>
                </a>{" "}
                if you have any issues{" "}
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};
