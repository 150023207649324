import React from "react";
// import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Modal from "../components/Modal";
import Hero from "../components/Hero";
import MainContent from "../components/MainContent";
import Support from "../components/Support";
import Author from "../components/Author";

import Layout from "../components/Layout";

const FETCH_CONTENT = graphql`query IndexQuery {
  markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
    frontmatter {
      templateKey
      title
      image {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      slides {
        image {
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
        }
      }
      story {
        title
        blurbs {
          image {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          text
        }
      }
      heading
      description
      closing {
        text
        image {
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
        }
      }
      quote
      author {
        heading
        name
        text
        image {
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
        }
      }
      support {
        title
        email {
          heading
          text
        }
        donate {
          heading
          text
          logo {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
        }
      }
      modal {
        show
        heading
        text
        logo {
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
        }
        image {
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
        }
      }
    }
  }
}
`;

const IndexPage = () => (
  <StaticQuery
    query={FETCH_CONTENT}
    render={(data) => {
      const { frontmatter } = data.markdownRemark;
      const {
        image,
        heading,
        slides,
        quote,
        description,
        story,
        closing,
        support,
        author,
        modal,
      } = frontmatter;

      return (
        <Layout>
          <Modal modal={modal} />
          <Hero image={image} heading={heading} description={description} />
          <MainContent
            story={story}
            slides={slides}
            closing={closing}
            quote={quote}
          />
          <Support support={support} />
          <Author author={author} />
        </Layout>
      );
    }}
  />
);

export default IndexPage;
