import React from "react";
import { StaticQuery, graphql } from "gatsby";

const FETCH_FOOTER = graphql`
  query FooterQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        footer {
          text
          email
        }
      }
    }
  }
`;

const Footer = () => (
  <StaticQuery
    query={FETCH_FOOTER}
    render={(data) => {
      const { footer } = data.markdownRemark.frontmatter;

      return (
        <footer id="contact" className="text-center py-48 lg:px-0 px-8">
          <div className="font-serif">
            <h4 className="text-3xl">{footer.text}</h4>
            <h4 className="text-3xl italic">
              <a href={`mailto:${footer.email}`}>
                <span className="border-b border-black pb-1">
                  {footer.email}
                </span>
              </a>
            </h4>
          </div>
        </footer>
      );
    }}
  />
);

export default Footer;
