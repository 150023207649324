import React from "react";

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
  min = 0,
  error = null,
}) => (
  <div className="flex flex-col my-3">
    <label htmlFor={id} className="font-serif text-left italic">
      {label}
    </label>
    {type === "number" ? (
      <div className="flex w-full relative">
        <span className="absolute left-0 top-0 mt-2 ml-2 z-10 text-gray-800">
          $
        </span>
        <input
          className="bg-transparent border w-full border-black font-serif px-5 py-2 focus:outline-none focus:bg-beige"
          id={id}
          type={type}
          placeholder={placeholder}
          required={required}
          autoComplete={autoComplete}
          value={value}
          min={min}
          onChange={onChange}
        />
      </div>
    ) : (
      <input
        className="bg-transparent border border-black font-serif px-3 py-2 focus:outline-none focus:bg-beige"
        id={id}
        type={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
      />
    )}
    {error && (
      <span className="font-serif italic mt-1 text-sm text-gray-700">
        {error}
      </span>
    )}
  </div>
);

export default Field;
