/* eslint-disable */

import React, { useState, useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Markdown from "react-markdown";
import "../styles/markdown.css";

export default ({ modal }) => {
  const [show, setShow] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setTimeout(() => setShow(modal.show), 1000);
    setTimeout(() => setFadeIn(true), 3000);
  }, []);

  const closeModal = () => {
    setFadeIn(false);
    setTimeout(() => setShow(false), 1000);
  };

  return (
    <>
      {show && (
        <div
          className={`fixed z-50 h-full bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:items-center sm:justify-center font-serif transition-opacity duration-700 md:max-w-full max-w-xl sm:flex hidden ${
            fadeIn ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            className={`fixed inset-0 transition-opacity`}
            onClick={closeModal}
          >
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>

          <div
            className={`bg-beige overflow-hidden shadow-xl transform transition-all sm:max-w-3xl sm:w-full m-auto`}
          >
            <div className="relative grid lg:grid-cols-2 grid-cols-1 bg-beige">
              <div className="px-8 py-6 lg:order-0 order-1">
                <h3 className="font-schnyder md:text-3xl text-2xl md:mb-4 mb-2">
                  {modal.heading}
                </h3>
                <div className="markdown">
                  <Markdown children={modal.text} />
                </div>
                <div className="mt-12 md:mb-6 mb-2 flex justify-between items-align">
                  <Link
                    to="/#support"
                    className="bg-brick text-white focus:outline-none px-5 py-3 text-lg font-serif uppercase"
                    onClick={closeModal}
                  >
                    Download
                  </Link>
                  <div className="my-auto">
                    <GatsbyImage
                      image={modal.logo.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="w-full lg:h-full sm:h-64 h-48 lg:order-1 order-0">
                <GatsbyImage
                  image={modal.image.childImageSharp.gatsbyImageData}
                  className="h-full"
                  alt=""
                />
              </div>

              <div
                className="absolute cursor-pointer w-8 h-8 mt-2 mr-2 top-0 right-0 z-10 order-last"
                onClick={closeModal}
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  fill="#FFFFFF"
                  viewBox="0 0 50 50"
                  enableBackground="new 0 0 50 50"
                >
                  <path
                    d="M9.016,40.837c0.195,0.195,0.451,0.292,0.707,0.292c0.256,0,0.512-0.098,0.708-0.293l14.292-14.309
  l14.292,14.309c0.195,0.196,0.451,0.293,0.708,0.293c0.256,0,0.512-0.098,0.707-0.292c0.391-0.39,0.391-1.023,0.001-1.414
  L26.153,25.129L40.43,10.836c0.39-0.391,0.39-1.024-0.001-1.414c-0.392-0.391-1.024-0.391-1.414,0.001L24.722,23.732L10.43,9.423
  c-0.391-0.391-1.024-0.391-1.414-0.001c-0.391,0.39-0.391,1.023-0.001,1.414l14.276,14.293L9.015,39.423
  C8.625,39.813,8.625,40.447,9.016,40.837z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
