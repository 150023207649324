import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Markdown from "react-markdown";
import "../styles/custom-grid.css";
import "../styles/markdown.css";

const Author = ({ author }) => (
  <section id="author">
    <div className="max-w-6xl mx-auto py-12">
      <h3 className="font-schnyder text-center lg:text-5xl text-3xl">
        {author.heading}
      </h3>
    </div>
    <div className="custom-grid grid grid-col-1">
      <div className="lg:col-start-1 lg:col-end-7">
        <GatsbyImage
          image={author.image.childImageSharp.gatsbyImageData}
          alt={author.heading}
        />
      </div>
      <div className="lg:col-start-8 lg:col-span-5 lg:row-start-1 lg:row-end-2 text-white z-10 h-full flex flex-col justify-center lg:bg-transparent bg-brick lg:px-0 lg:py-0 px-8 py-12">
        <h4 className="font-schnyder lg:text-5xl text-3xl mb-6">
          {author.name}
        </h4>
        <div className="font-serif text-xl markdown">
          <Markdown children={author.text} />
        </div>
      </div>
      <div className="lg:col-start-7 lg:col-span-8 lg:row-start-1 lg:row-end-2 bg-brick"></div>
    </div>
  </section>
);

export default Author;
