import React, { useState } from "react";

const EmailForm = () => {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");

  return (
    <div id="mc_embed_signup">
      <form
        action={`https://mylifeinamber.us4.list-manage.com/subscribe/post?u=2a89573f1fc6b93d63a7e0a3b&amp;id=f1b43c639e`}
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="w-full py-8 flex flex-col validate"
        target="_blank"
        noValidate
      >
        <div id="mc_embed_signup_scroll">
          <div className="mc-field-group flex flex-col my-3">
            <label
              className="font-serif text-left italic ml-0"
              htmlFor="mce-FNAME"
            >
              First Name*
            </label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              name="FNAME"
              className="bg-transparent border border-black font-serif px-3 py-2 focus:outline-none focus:bg-beige"
              id="mce-FNAME"
            />
          </div>

          <div className="mc-field-group flex flex-col my-3">
            <label className="font-serif text-left italic" htmlFor="mce-EMAIL">
              Email Address*
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="EMAIL"
              className="required email bg-transparent border border-black font-serif px-3 py-2 focus:outline-none focus:bg-beige"
              id="mce-EMAIL"
            />
          </div>

          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: "none" }}
            ></div>
            <div
              className="response"
              id="mce-success-response"
              style={{ display: "none" }}
            ></div>
          </div>

          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_2a89573f1fc6b93d63a7e0a3b_f1b43c639e"
              tabIndex="-1"
              defaultValue=""
            />
          </div>

          <div className="clear flex mt-4">
            <button
              type="submit"
              className="bg-brick text-white focus:outline-none px-5 py-3 mt-6 ml-0 text-lg font-serif uppercase"
            >
              Subscribe
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EmailForm;
