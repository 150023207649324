import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Markdown from "react-markdown";
import "../styles/markdown.css";
import EmailForm from "./EmailForm";
import DonateForm from "./DonateForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

const Support = ({ support }) => (
  <section id="support" className="bg-white py-12 mt-24">
    <div className="max-w-6xl mx-auto">
      <h3 className="font-schnyder text-center lg:text-5xl text-4xl py-12">
        {support.title}
      </h3>
      <div className="grid grid-cols-1 lg:grid-cols-1">
        <div className="flex flex-col justify-between items-center px-8">
          <div className="text-center mb-6">
            <h5 className="font-schnyder text-3xl mb-8">
              {support.email.heading}
            </h5>
            <div className="font-serif text-lg markdown">
              <Markdown children={support.email.text} />
            </div>
            <EmailForm />
          </div>
        </div>
        {/* Hiding Donate Form */}
        <div className="hidden flex flex-col justify-between items-center px-8">
          <div className="text-center mb-6">
            <h5 className="font-schnyder text-3xl mb-8">
              {support.donate.heading}
            </h5>
            <div className="font-serif text-lg markdown grid grid-cols-3 gap-6">
              <Markdown
                className="flex col-span-2 md:text-left"
                source={support.donate.text}
              />
              <div className="m-auto col-span-1 w-24">
                <GatsbyImage
                  image={support.donate.logo.childImageSharp.gatsbyImageData}
                />
              </div>
            </div>
          </div>
          <Elements stripe={stripePromise}>
            <DonateForm />
          </Elements>
        </div>
      </div>
    </div>
  </section>
);

export default Support;
