import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Markdown from "react-markdown";
import "../styles/custom-grid.css";
import "../styles/markdown.css";

const MainContent = ({ story, slides, closing, quote }) => (
  <>
    <section id="story">
      {/* Title */}
      <div className="max-w-6xl mx-auto py-24">
        <h4 className="font-schnyder text-center lg:text-6xl text-4xl">
          {story.title}
        </h4>
      </div>

      {/* Title */}
      <div className="mx-auto lg:pt-12">
        <div className="custom-grid grid grid-cols-1 w-full">
          <div className="lg:col-start-1 lg:col-span-8 lg:row-start-1 lg:row-end-2 w-full h-full lg:mb-24 lg:-mt-12 ">
            <div className="w-full h-full">
              <GatsbyImage
                image={story.blurbs[0].image.childImageSharp.gatsbyImageData}
                alt={story.title + "-1"}
              />
            </div>
          </div>
          <div className="lg:col-start-10 lg:col-span-4 lg:row-start-1 lg:row-end-2 flex flex-col justify-center z-10 lg:-mt-12">
            <div className="font-serif text-2xl text-white lg:mb-12 lg:px-0 lg:py-0 px-8 pt-16 pb-12 lg:bg-transparent bg-brick markdown">
              <Markdown children={story.blurbs[0].text} />
            </div>
          </div>
          <div className="lg:col-start-8 lg:col-span-7 lg:row-start-1 lg:row-end-2 bg-brick"></div>
        </div>
      </div>

      <div className="mx-auto">
        <div className="grid custom-grid grid-cols-1">
          <div className="order-2 lg:col-start-7 lg:col-span-8">
            <GatsbyImage
              image={story.blurbs[1].image.childImageSharp.gatsbyImageData}
              alt={story.title + "-2"}
            />
          </div>
          <div className="order-1 lg:col-start-2 lg:col-span-4 flex flex-col justify-between lg:px-0 lg:py-0 px-8 py-16">
            <div className="my-auto">
              <div className="text-black font-serif text-2xl">
                <Markdown children={story.blurbs[1].text} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-6xl grid lg:grid-cols-12 grid-cols-1 mx-auto lg:py-24">
        <div className="lg:col-start-2 lg:col-span-10 lg:px-0 lg:py-0 px-8 py-16">
          <div className="font-serif text-black text-2xl lg:text-center text-left leading-10 markdown">
            <Markdown children={story.blurbs[2].text} />
          </div>
        </div>
      </div>
    </section>
    {/* Slides */}
    <section className="grid grid-cols-1 lg:grid-cols-3 lg:gap-4">
      <GatsbyImage
        image={slides[0].image.childImageSharp.gatsbyImageData}
        alt="slide-1"
      />
      <GatsbyImage
        image={slides[1].image.childImageSharp.gatsbyImageData}
        alt="slide-2"
      />
      <GatsbyImage
        image={slides[2].image.childImageSharp.gatsbyImageData}
        alt="slide-3"
      />
    </section>
    {/* Closing */}
    <section>
      <div className="max-w-6xl grid grid-cols-1 mx-auto lg:py-24 lg:px-0 px-8 py-16">
        <h4 className="font-schnyder text-center lg:text-5xl text-3xl leading-12">
          {closing.text}
        </h4>
      </div>
      <div className="lg:pt-12">
        <div className="grid custom-grid grid-cols-1">
          <div className="lg:col-start-2 lg:col-span-7 lg:row-start-1 lg:row-end-2 lg:p-0 p-4 lg:z-0 z-10">
            <GatsbyImage
              image={closing.image.childImageSharp.gatsbyImageData}
              alt={closing.text}
            />
          </div>
          <div className="lg:col-start-10 lg:col-span-4 lg:row-start-1 lg:row-end-2 lg:z-10 lg:bg-transparent bg-brick lg:pt-0 pt-48 px-8 pb-12 -mt-48">
            <div className="h-full flex flex-col lg:pt-0 pt-12 justify-center">
              <div className="my-auto text-2xl font-serif text-white">
                <Markdown children={quote} />
              </div>
            </div>
          </div>
          <div className="lg:col-start-8 lg:col-span-7 lg:row-start-1 lg:row-end-2 lg:pl-24 lg:-mt-12 w-full h-full lg:p-6 lg:bg-brick"></div>
        </div>
      </div>
    </section>
  </>
);

export default MainContent;
