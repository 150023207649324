import React from "react";
import Logo from "./Logo";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Hero = ({ image, heading, description }) => (
  <section className="bg-dark py-24">
    <div className="relative grid grid-cols-1 grid-rows-1 lg:grid-cols-2 max-w-6xl mx-auto">
      <div className="absolute z-10 h-full flex flex-col justify-center row-span-1 col-span-1 lg:row-auto lg:col-auto lg:relative lg:px-0 px-8">
        <div className="mb-2 pr-24">
          <Logo fill="#fff" />
        </div>
        <h2 className="my-4 text-white text-lg font-serif italic font-light">
          {heading}
        </h2>
        <p className="my-4 text-white text-lg font-serif">{description}</p>
        {/* <a
          href="https://mylifeinamber.gumroad.com/l/cfmcz"
          target="_blank"
          rel="noopener noreferrer"
          className="font-serif text-white text-lg mt-6"
        >
          <span className="bg-brick rounded-sm text-white focus:outline-none px-5 py-3 mt-6 ml-0 text-lg font-serif uppercase">
            Pre-Order Now
          </span>
        </a> */}
      </div>
      <div className="row-span-1 col-span-1 lg:row-auto lg:col-auto">
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          alt={heading}
        />
      </div>
    </div>
  </section>
);

export default Hero;
